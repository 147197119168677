import styled from "styled-components";

export const FarmingBox = styled.div`
  width: calc(50% - 8px);
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
  @media only screen and (max-width: 1440px) {
    width: calc(60% - 8px);
  }
  @media only screen and (max-width: 820px) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    padding: 0px 16px;
  }
`;
export const FarmingContent = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  background: var(--Gray-90, #2d2f35);
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const BalanceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  @media only screen and (max-width: 764px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const BoxBlanceAss = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  img {
    width: 28px;
  }
  div {
    display: flex;
    flex-direction: column;
    h3 {
      color: var(--Gray-30, #c3c6cf);
      leading-trim: both;
      text-edge: cap;
      font-family: "Anek Odia";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 123.077%;
    }
    p {
      color: var(--Light-Gray-10, #f8f9fb);
      leading-trim: both;
      text-edge: cap;
      font-family: "Plus Jakarta Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%; /*  */
    }
  }
`;

export const BalanceTonBox = styled.div`
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 4px);
  border-radius: 30px;
  border: 0.493px solid #bbf7c6;
  align-self: stretch;
  background: linear-gradient(180deg, #25376e -56.5%, #0043ec 156.13%);
  box-shadow: -1.972px 1.972px 2.835px 0px #fff inset,
    1.972px -1.972px 2.835px 0px #fff inset;
  ul {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0.493px solid #bbf7c650;
    align-self: stretch;
    background: linear-gradient(
      180deg,
      rgb(0, 0, 0) -56.5%,
      rgb(0, 0, 0) 156.13%
    );
    box-shadow: -1.972px 1.972px 2.835px 0px #ffffff50 inset,
      1.972px -1.972px 2.835px 0px #ffffff50 inset;
    border-radius: 10px;
    top:0px;
    left:0px;
    z-index: -1;
    opacity: 0;
    padding: 10px;
    li {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }
  @media only screen and (max-width: 764px) {
    width: 100%;
  }
`;
export const BalanceItonBox = styled.div`
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 4px);
  border-radius: 30px;
  border: 0.493px solid #bbf7c6;
  background: linear-gradient(180deg, #878787 -56.5%, #eee 156.13%);
  box-shadow: -1.972px 1.972px 2.835px 0px #fff inset,
    1.972px -1.972px 2.835px 0px #fff inset;
  ${BoxBlanceAss} {
    div {
      h3 {
        color: var(--Gray-80, #444750);
      }
      p {
        color: var(--Gray-Dark, #121315);
      }
    }
  }
  @media only screen and (max-width: 764px) {
    width: 100%;
  }
`;
export const BtnFarimg = styled.a`
  display: flex;
  padding: 7.051px 9.402px;
  justify-content: center;
  align-items: center;
  gap: 2.435px;
  border-radius: 17.308px;
  background: radial-gradient(50% 50% at 50% 50%, #55aeff 0%, #0085ff 100%);
  box-shadow: 0px 0px 0.588px 2.35px rgba(255, 255, 255, 0.1),
    0px -2.35px 1.175px 0px rgba(0, 0, 0, 0.25) inset,
    0px 1.175px 0.588px 0px rgba(255, 255, 255, 0.25) inset;
  border: none;
  outline: none;
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: "Plus Jakarta Sans";
  font-size: 9.972px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
`;

export const BoxAmountBalance = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const BoxAmountTon = styled.div`
  display: flex;
  padding: 8px 8px 10px 8px;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 8px);
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 0.493px solid #bbf7c6;
  background: linear-gradient(180deg, #25376e -56.5%, #0043ec 156.13%);
  box-shadow: -1.972px 1.972px 2.835px 0px #fff inset,
    1.972px -1.972px 2.835px 0px #fff inset;
`;
export const BoxAmountITon = styled.div`
  display: flex;
  padding: 8px 8px 10px 8px;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 8px);
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 0.493px solid #bbf7c6;
  background: linear-gradient(180deg, #878787 -56.5%, #eee 156.13%);
  box-shadow: -1.972px 1.972px 2.835px 0px #fff inset,
    1.972px -1.972px 2.835px 0px #fff inset;
`;
export const TonMes = styled.div`
  h1 {
    color: #fff;
    font-family: "Rethink Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  p {
    color: #ff4949;
    leading-trim: both;
    text-edge: cap;
    font-family: "Anek Odia";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const BoxNumberAmount = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    max-width: 28px;
  }
  p {
    color: #fff;
    text-align: right;
    font-family: "Rethink Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 18px */
    text-transform: uppercase;
  }
`;

export const BtnFarm = styled.button`
  display: flex;
  height: 48px;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 33px;
  background: var(--Blue-Base, #0085ff);
  width: 100%;
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: "Anek Odia";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 112.5%; /*  */
  letter-spacing: 0.16px;
  border: none;
  outline: none;
`;
export const FarmedBox = styled.div`
  width: calc(50% - 8px);
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Gray-100, #1b1c20);
  gap: 16px;
  padding: 16px;
  @media only screen and (max-width: 1440px) {
    width: calc(40% - 8px);
  }
  @media only screen and (max-width: 820px) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% - 32px);
    margin: 0px 16px;
  }
`;

export const FarmedConten = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
  gap: 16px;
`;

export const FramedDetail = styled.div`
  display: flex;
  padding: 16px 14px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 16px;
  width: calc(50% - 8px);
  gap: 16px;
  &:nth-child(1),
  &:nth-child(2) {
    background-image: url("https://app.tonholding.io/assets/Card_Farm.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &:nth-child(3),
  &:nth-child(4) {
    background: #1a3651;
  }
  img {
    max-width: 40px;
  }
`;
export const CardDetail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    color: var(--Gray-40, #afb4c0);
    font-family: "Anek Odia";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: var(--Light-Gray-10, #f8f9fb);
      font-family: "Plus Jakarta Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /*  */
      letter-spacing: 0.3px;
      gap: 6px;
      display: flex;
      align-items: center;
      img {
        max-width: 20px;
      }
    }
  }
`;
