import { useSelector } from "react-redux";
import { BoxNotification, Submit } from "../../Staking/styled";
import { BoxSelectWallet, WithdrawInput, WithdrawInputAmount } from "../style";
import { formatNumber } from "../../../utils/formatNumber";
import NotiIcon from "../../../assets/Staking/CompoundBadge.svg";
import { useEffect, useState } from "react";
import { getValueWidthrawByDay } from "../../../store/Slices/Withdraw";
import { useDispatch } from "../../../store/reDucStore";
import toast from "react-hot-toast";

const WidthRawBox = ({
  amount,
  setAmount,
  fee,
  setFee,
  handleWithdraw,
  loadingSub,
  hadleGetValByDay,
  maxByDay,
}: any) => {
  const balanceWithdraw = useSelector(
    (state: any) => state.withdraw.balanceWithdraw
  );
  const TokenInfo = useSelector((state: any) => state.token.TokenInfo);
  const Fee = useSelector((state: any) => state.withdraw.settingList);
  const dispatch = useDispatch();
  useEffect(() => {
    hadleGetValByDay();
  }, []);
  return (
    <WithdrawInput>
      <WithdrawInputAmount>
        <h3>
          Amount{" "}
        </h3>
        <div>
          <input
            type="number"
            pattern="0.00"
            value={amount ? amount : ""}
            onChange={(e: any) => {
              e.target.value >= 0 && setAmount(e.target.value);
            }}
          />
          <button
            onClick={() => {
              setAmount(Math.floor(Number(balanceWithdraw?.money_token)) || 0);
            }}
          >
            Max
          </button>
        </div>
        <p>
          ~$
          {TokenInfo?.prices?.USD
            ? formatNumber(
                (Number(amount) * TokenInfo?.prices?.USD)?.toFixed(2)
              )
            : 0}{" "}
        </p>
        <BoxNotification>
          <div>
            <img src={NotiIcon} alt="icon" />
            <p>Fee Withdraw</p>
          </div>
          <h6>
            <span>{fee}%</span>{" "}
            {`(~$${
              fee
                ? `${
                    TokenInfo?.prices?.USD
                      ? formatNumber(
                          (
                            (Number(amount) * TokenInfo?.prices?.USD * fee) /
                            100
                          )?.toFixed(2)
                        )
                      : 0
                  }`
                : "0"
            })`}
          </h6>
        </BoxNotification>
        <h3 style={{ marginTop: "10px" }}> Minimum withdrawal $20</h3>
        <h3>
          <span style={{ lineHeight: "20px" }}>
            Your available withdrawal limit for the day is{" "}
            {Number(maxByDay).toFixed(2)} TON{" "}
            <img src="/assets/ton_symbol.svg" />
          </span>
        </h3>
        <Submit
          onClick={async () => {
            // if (Number(amount) > maxByDay) {
            //   toast.error("Exceed the daily withdrawal limit");
            //   return;
            // }
            // console.log("runnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");

            // !loadingSub && handleWithdraw();
            // hadleGetValByDay();
          }}
          style={{
            opacity:"0.5",
              // amount &&
              // Number(amount) <= maxByDay
              //   ? "1"
              //   : "0.5",
              cursor: "not-allowed",
          }}
        >
          {" "}
          {loadingSub ? "Loading..." : "Withdraw"}
        </Submit>
      </WithdrawInputAmount>
    </WithdrawInput>
  );
};
export default WidthRawBox;
