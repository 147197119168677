import { CheckCircleOutlined, CloseCircleOutlined, WarningOutlined } from "@ant-design/icons";

export const TonOnChain = [
  {
    key: "1",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CheckCircleOutlined style={{ fontSize: 14, color: "rgb(0 255 20)" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Withdraw
        </p>
      </div>
    ),
  },
  {
    key: "2",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CheckCircleOutlined style={{ fontSize: 14, color: "rgb(0 255 20)" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Transfer
        </p>
      </div>
    ),
  },
  {
    key: "3",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CheckCircleOutlined style={{ fontSize: 14, color: "rgb(0 255 20)" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Farm
        </p>
      </div>
    ),
    
  },
  {
    key: "4",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CloseCircleOutlined style={{ fontSize: 14, color: "red" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Stake
        </p>
      </div>
    ),
    
  },
];
export const ton_internal = [
  {
    key: "1",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CloseCircleOutlined style={{ fontSize: 14, color: "red" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Withdraw
        </p>
      </div>
    ),
  },
  {
    key: "2",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CloseCircleOutlined style={{ fontSize: 14, color: "red" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Transfer
        </p>
      </div>
    ),
  },
  {
    key: "3",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CheckCircleOutlined style={{ fontSize: 14, color: "rgb(0 255 20)" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Farm
        </p>
      </div>
    ),
    
  },
  {
    key: "4",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CloseCircleOutlined style={{ fontSize: 14, color: "red" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Stake
        </p>
      </div>
    ),
    
  },
];

export const iTonOnChain = [
  {
    key: "1",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CloseCircleOutlined style={{ fontSize: 14, color: "red" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Withdraw
        </p>
      </div>
    ),
  },
  {
    key: "2",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CloseCircleOutlined style={{ fontSize: 14, color: "red" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Transfer
        </p>
      </div>
    ),
  },
  {
    key: "3",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CheckCircleOutlined style={{ fontSize: 14, color: "rgb(0 255 20)" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Farm
        </p>
      </div>
    ),
    
  },
  {
    key: "4",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CheckCircleOutlined style={{ fontSize: 14, color: "rgb(0 255 20)" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Stake
        </p>
      </div>
    ),
  },
];

export const iTonReward = [
  {
    key: "1",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CloseCircleOutlined style={{ fontSize: 14, color: "red" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Withdraw
        </p>
      </div>
    ),
  },
  {
    key: "2",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CloseCircleOutlined style={{ fontSize: 14, color: "red" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Transfer
        </p>
      </div>
    ),
  },
  {
    key: "3",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CloseCircleOutlined style={{ fontSize: 14, color: "red" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Farm
        </p>
      </div>
    ),
    
  },
  {
    key: "4",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <CloseCircleOutlined style={{ fontSize: 14, color: "red" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          Stake
        </p>
      </div>
    ),
  },
  {
    key: "4",
    label: (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <WarningOutlined style={{ fontSize: 14, color: "rgb(253 255 0)" }} />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            color: "#fff",
          }}
        >
          15% monthly
        </p>
      </div>
    ),
  },
];