import React, {
  createContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import styled from "styled-components";
import {
  LogoutButton,
  SideBarContent,
  SideBarLink,
} from "../components/sidebar/style";
import { LangData, NavData } from "../components/Contant";
import { NavLink } from "react-router-dom";
import DropSlelect, { ArrowIcon, BoxSelect } from "../components/inputSelect";

const MenuContext = createContext<any>(null);

const MenuProvider = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("Dashboard");
  const [des, setDes] = useState("");
  const [languageValue, setLanguageValue] = useState(LangData[0]);
  const [customPopup, setCustomPopup] = useState<any>();
  const handleOpenCustom = (elm: any) => {
    setCustomPopup(elm);
    setIsOpen(true);
  };
  const handleCloseCustom = (elm: any) => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (!isOpen) {
      setCustomPopup(null);
    }
  }, [isOpen]);

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        setIsOpen,
        title,
        setTitle,
        des,
        setDes,
        handleOpenCustom,
        handleCloseCustom,
      }}
    >
      <WapperProvider open={isOpen} iscustom={customPopup}>
        <ModalLayout
          open={isOpen}
          iscustom={customPopup}
          onClick={() => {
            customPopup && setIsOpen(false);
          }}
        >
          {customPopup ? (
            customPopup
          ) : (
            <>
              {/* <DropSlelect
                list={LangData}
                active={languageValue}
                setActive={setLanguageValue}
              /> */}
              <MenuMobile setIsOpen={setIsOpen} />
            </>
          )}
        </ModalLayout>
        {children}
      </WapperProvider>
    </MenuContext.Provider>
  );
};

export { MenuContext, MenuProvider };

const MenuMobile = ({ setIsOpen }: any) => {
  return (
    <SideBarContent>
      <SideBarLink isshow={true}>
        {NavData.map((link) => {
          return (
            <NavLink
              to={link.link}
              key={link.link}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <div>
                <img src={link.icon} />
              </div>{" "}
              <p>{link.title}</p>
            </NavLink>
          );
        })}
      </SideBarLink>
      {/* <LogoutButton isshow={true}>
        <img src="https://app.tonholding.io/assets/IconLogout.svg" />
        <p>Logout</p>
      </LogoutButton> */}
    </SideBarContent>
  );
};

const WapperProvider = styled.div<{ open?: any; iscustom?: any }>`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: ${({ open }) =>
    open ? " hidden;" : "auto"};
`;
const ModalLayout = styled.div<{ open?: any; iscustom?: any }>`
  position: fixed;
  width: 100%;
  height: ${({ open, iscustom }) =>
    open ? (iscustom ? "100%" : "calc(100% - 108px)") : 0};
  z-index: ${({ iscustom }) => (iscustom ? "9999" : "9999")};
  top: ${({ iscustom }) => (iscustom ? "0px" : "108px")};
  transition: all 0.3s linear;
  background-color: ${({ iscustom }) =>
    iscustom ? "rgba(18, 19, 21, 0.5)" : "rgba(18, 19, 21, 1)"};
  padding: ${({ open }) => (open ? "15px" : 0)};
  overflow: hidden;
  ${SideBarContent} {
    padding-top: 0px;
  }
  ${BoxSelect} {
    display: none;
  }

  @media only screen and (min-width: 991px) {
    display: ${({ iscustom }) => (iscustom ? "flex" : "none")};
  }
  @media only screen and (max-width: 768px) {
    top: 82px;
    height: ${({ open }) => (open ? "calc(100% - 82px)" : 0)};
    padding-bottom: ${({ open }) => (open ? "30px" : 0)};
    ${BoxSelect} {
      display: flex !important;
      width: 100%;
      position: relative;
      z-index: 5;
      margin-bottom: 16px;
    }
    ${ArrowIcon} {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
  }
`;
