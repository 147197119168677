import { useEffect, useState } from "react";
import { BoxDropList, ListElm, ListUl } from "./style";

const DropList = ({ list, size }: any) => {
  const [showList, setShowList] = useState(false);
  const [selected, setSelected] = useState("");
  useEffect(() => {
    if (list && list[0].title) {
      setSelected(list[0].title);
    }
  }, [list]);
  return (
    <>
      {list ? (
        <>
          <BoxDropList isshow={showList}>
            <input type="text" onFocus={()=>{setShowList(true)}} onBlur={()=>{setShowList(false)}} />
            <p>{selected}</p>
            <img
              src="./assets/arrowBlue.svg"
              style={{ transform: `rotateZ(${showList ? 180 : 0}deg)` }}
            />
          </BoxDropList>
          <ListUl isshow={showList}>
            {list?.map((el: any) => (
              <ListElm lock={el.lock} key={el.title}>
                {el.title} {el.lock ? <span>Closed</span> : <></>}
              </ListElm>
            ))}
          </ListUl>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default DropList;
