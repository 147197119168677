import { useContext, useEffect, useRef, useState } from "react";
import { ContextProviderWrapper } from "../../../components/Context";
import { BoxTitleRadius } from "../../Dashboard/styled";
import {
  BoxBoxBlance,
  BoxInputTyprNumber,
  BoxNotification,
  InputStakeBox,
  InvestWalletList,
  StakeTON,
  StakeTONConten,
  Submit,
  TextUsdt,
} from "../styled";
import { sha256 } from "js-sha256";
import { useBalance } from "../../../hook/useTonBalance";
import { RootState, useSelector } from "../../../store/reDucStore";
import {
  InvestStatus,
  SubmitInvest,
  getAddresAdmin,
  getInvestInfo,
} from "../../../store/Slices/invesmens";
import { fromNano, toNano } from "@ton/core";
import toast from "react-hot-toast";
import {
  useTonAddress,
  useTonConnectUI,
  useTonWallet,
  useIsConnectionRestored,
} from "@tonconnect/ui-react";
import NotiIcon from "../../../assets/Staking/Noti.svg";
import { formatNumber } from "../../../utils/formatNumber";
import axios from "axios";
import { Coins } from "ton3-core";
import { getDasboardInfo } from "../../../store/Slices/Dashboard";
import { getTokenInfo } from "../../../store/Slices/Token";
import {
  getBalance,
  getFee,
  getWidthrawList,
} from "../../../store/Slices/Withdraw";
import { useDispatch } from "react-redux";
import { instance } from "../../../store/instance";
import { useGetPayloadMutation } from "../../../store/api/authApiSlice";
import { useAuth } from "../../../components/buttonConnect";
import ModalConfirm from "../ConfirmStake";
import { MenuContext } from "../../../Provider/MenuProvider";
import { PushNotiNode } from "../../../utils/notiBot";
import DropList from "../dropList";
import { BalanceTonBox, BoxBlanceAss, BtnFarimg } from "../../Farming/style";
const payloadTTLMS = 1000 * 60 * 20;
const StakeTon = ({iTonPrice}:any) => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const connectionRestored = useIsConnectionRestored();
  const adminAddress = useSelector(
    (state: RootState) => state.investmens.adminAddress
  );
  const [amount, setAmount] = useState(0);
  const [loadingSub, setLoadingSub] = useState(false);
  const addressHas = useTonAddress(false);
  const TokenInfo = useSelector((state: RootState) => state.token.TokenInfo);
  const [tonConnectUI] = useTonConnectUI();
  const wallet: any = useTonWallet();
  const [tonBalance, setTonBalance] = useState<any>(0);
  const [typeInvest, setTypeInvest] = useState<any>(1);
  const [showList, setShowList] = useState<any>(false);
  const timeOut = useRef<any>();
  const dispatch = useDispatch();
  const address = useTonAddress();
  const { setTitle, setDes, handleOpenCustom, isOpen }: any =
    useContext(MenuContext);
  useEffect(() => {
    getBalanceTon();
  }, [address]);
  useEffect(() => {
    if (localStorage.getItem("token") && address) {
      // @ts-ignore
      dispatch(getDasboardInfo());
      // @ts-ignore
      dispatch(getTokenInfo());
      // @ts-ignore
      dispatch(getWidthrawList());
      // @ts-ignore
      dispatch(getBalance());
      // @ts-ignore
      dispatch(getFee());
    }
  }, [localStorage.getItem("token"), address]);
  const balanceWithdraw = useSelector(
    (state: RootState) => state.withdraw.balanceWithdraw
  );
  const getBalanceTon = async () => {
    await axios
      .get(`https://tonapi.io/v2/accounts/${wallet?.account?.address}`)
      .then((response: any) => {
        setTonBalance(Coins.fromNano(response?.data?.balance || 0));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleInvestment = async () => {
    if (amount > 0) {
      if (
        !adminAddress.address_invest ||
        !Number(amount) ||
        !TokenInfo?.prices?.USD
      ) {
        toast.error("There is an error. Please try again.");
        return;
      }
      if (amount < 100) {
        toast.error("At least $100");
        return;
      }
      let amountSubmit = `${toNano(
        (Number(amount) + 1) / TokenInfo?.prices?.USD
      )}`;
      console.log("amountSubmit", amountSubmit);

      setLoadingSub(true);
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: "UQDSqbuhV3j1cq-n6qiU6viwz8DKQaUjNCDYCHJhCLT_NWwz",
            // amount: `${toNano(amount)}`,
            amount: amountSubmit,
          },
        ],
      };
      try {
        // const apiKey = sha256.hex(
        //   `hZcxtc2wQ2KfiKQnHxB322pE9DPxWGAMduADBAwtkr9MerMLW8${address}`
        // );
        const handleTx = async () => {
          const res = await tonConnectUI.sendTransaction(transaction);
          if (res.boc) {
            await dispatch(
              // @ts-ignore
              SubmitInvest({
                amount: Number(amount) / Number(TokenInfo?.prices?.USD),
              })
            );
            // @ts-ignore
            await dispatch(InvestStatus());
            toast.success(
              "The investment package is being updated. Please wait a few minutes."
            );
          }
        };
        handleOpenCustom(
          <ModalConfirm
            handleConfirm={handleTx}
            tabsActive={typeInvest}
            amount={(Number(amount) + 1) / TokenInfo?.prices?.USD}
            setLoadingSub={setLoadingSub}
            // address={revierAddress}
          />
        );
      } catch (e) {
        console.log("e--------------------------", e);
      }
      timeOut.current = setTimeout(() => {
        setAmount(0);
        setLoadingSub(false);
      }, 3000);
    } else {
      toast.error("invalid amount");
    }
  };

  const handleInvestIternal: any = async () => {
    if (amount > 0) {
      if (
        !adminAddress.address_invest ||
        !Number(amount) ||
        !TokenInfo?.prices?.USD
      ) {
        toast.error("There is an error. Please try again.");
        return;
      }
      if (amount < 100) {
        toast.error("At least $100");
        return;
      }

      const backupResponse = await axios.get(
        "https://app.tonholding.io/api/getprice"
      );
      if (backupResponse.status !== 200) {
        PushNotiNode(
          `https://app.tonholding.io/api/getprice`,
          backupResponse.status,
          JSON.stringify(backupResponse)
        );
      }
      const confirm = async () => {
        setLoadingSub(true);
        try {
          let res = await instance.post("/investments/internal", {
            amount:
              Number(
                Number(amount) /
                  backupResponse?.data?.rates?.TON?.prices?.USD ||
                  TokenInfo?.prices?.USD
              ) * 0.95,
          });
          console.log("res===================", res);
          setLoadingSub(false);
          if (res.status === 202) {
            toast.success(
              "The investment package is being updated. Please wait a few minutes."
            );
            // @ts-ignore
            dispatch(getDasboardInfo());
            // @ts-ignore
            dispatch(getTokenInfo());
            // @ts-ignore
            dispatch(getWidthrawList());
            // @ts-ignore
            dispatch(getBalance());
            // @ts-ignore
            dispatch(getFee());
          }
          setLoadingSub(false);
          return res;
        } catch (error) {
          setLoadingSub(false);
        }
      };
      handleOpenCustom(
        <ModalConfirm
          handleConfirm={confirm}
          tabsActive={typeInvest}
          amount={(Number(amount) + 1) / TokenInfo?.prices?.USD}
          setLoadingSub={setLoadingSub}
        />
      );
      // console.log("res", ress);
    } else {
      alert("Please try another wallet");
      tonConnectUI.disconnect();
    }
  };

  const handleInvestITon: any = async () => {
    if (amount > 0) {
      if (!adminAddress.address_invest || !Number(amount)) {
        toast.error("There is an error. Please try again.");
        return;
      }
      // if (amount < 100) {
      //   toast.error("At least $100");
      //   return;
      // }
      // if (backupResponse.status !== 200) {
      //   PushNotiNode(
      //     `https://app.tonholding.io/api/getprice`,
      //     backupResponse.status,
      //     JSON.stringify(backupResponse)
      //   );
      // }
      const confirm = async () => {
        setLoadingSub(true);
        try {
          let res = await instance.post("staking/stake-iton", {
            amount_usd: Number(Number(amount)),
          });
          console.log("res===================", res);
          setLoadingSub(false);
          if (res.data?.is_sucess) {
            toast.success("Stake successfully");
            // @ts-ignore
            dispatch(getDasboardInfo());
            // @ts-ignore
            dispatch(getTokenInfo());
            // @ts-ignore
            dispatch(getWidthrawList());
            // @ts-ignore
            dispatch(getBalance());
            // @ts-ignore
            dispatch(getFee());
            // @ts-ignore
            dispatch(getInvestInfo());
            // @ts-ignore
            dispatch(getAddresAdmin());
          } else {
            toast.error(res.data?.message);
          }
          setLoadingSub(false);
          return res;
        } catch (error) {
          setLoadingSub(false);
        }
      };
      handleOpenCustom(
        <ModalConfirm
          handleConfirm={confirm}
          tabsActive={typeInvest}
          amount={Number(amount) / iTonPrice}
          setLoadingSub={setLoadingSub}
        />
      );
      // console.log("res", ress);
    } else {
      alert("Please try another wallet");
      tonConnectUI.disconnect();
    }
  };

  useEffect(() => {
    if (amount === 0 && timeOut.current) {
      clearTimeout(timeOut.current);
    }
  }, [amount]);
  return (
    <StakeTON>
      <BoxTitleRadius>
        {!isMobile && (
          <>
            <h3>Staking Mode</h3>{" "}
            <DropList
              list={[
                {
                  title: "iTON Stake",
                  lock: false,
                },
                {
                  title: "TON Stake",
                  lock: true,
                },
              ]}
            />
          </>
        )}
      </BoxTitleRadius>
      <StakeTONConten>
        <InputStakeBox>
          <BalanceTonBox style={{ width: "100%" }}>
            <BoxBlanceAss>
              <img
                style={{ borderRadius: "50%", border: "1px solid #fff" }}
                src="/assets/iton_symbol.svg"
              />
              <div>
                <h3>iTON Balance</h3>
                <p>
                  {Number(balanceWithdraw?.money_iton || 0).toFixed(2) || 0}
                </p>
              </div>
            </BoxBlanceAss>
            <BtnFarimg href="https://exchange.iton.co/buy-ido" target="_blance" >
              <img src="/assets/Arrow-Right.svg" />
              <p>Buy More</p>
            </BtnFarimg>
          </BalanceTonBox>
          <BoxInputTyprNumber>
            <h6>Amount ($)</h6>
            <div>
              <input
                type="number"
                pattern="0.00"
                value={amount ? amount : ""}
                onChange={(e: any) => {
                  e.target.value >= 0 && setAmount(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  setAmount(Number(balanceWithdraw?.money_iton) * iTonPrice);
                  // typeInvest === 1
                  //   ? Number(tonBalance.toString()) &&
                  //     TokenInfo?.prices?.USD &&
                  //     setAmount(
                  //       Number(tonBalance.toString()) * TokenInfo?.prices?.USD
                  //     )
                  //   : setAmount(
                  //       Number(balanceWithdraw?.money_token) *
                  //         TokenInfo?.prices?.USD
                  //     );
                }}
              >
                Max
              </button>
            </div>
          </BoxInputTyprNumber>
          <TextUsdt>
            ~
            {iTonPrice
              ? formatNumber((Number(amount) / iTonPrice)?.toFixed(2))
              : "--"}{" "}
            <img src="./assets/iton_symbol.svg" style={{ maxHeight: "20px" }} />
          </TextUsdt>
        </InputStakeBox>
        <BoxNotification>
          <div>
            <img src={NotiIcon} alt="icon" />
          </div>
          <p>Start by staking at least $100 worth of $iTON</p>
        </BoxNotification>
        <Submit
          onClick={() => {
            !loadingSub &&
              amount &&
              iTonPrice &&
              Number(amount) / iTonPrice <=
                Number(balanceWithdraw?.money_iton) &&
              handleInvestITon();
            // typeInvest === 1
            //   ? !loadingSub &&
            //     amount &&
            //     Number(amount) / TokenInfo?.prices?.USD <=
            //       Number(tonBalance.toString()) &&
            //     handleInvestment()
            //   : !loadingSub &&
            //     amount &&
            //     Number(amount) / TokenInfo?.prices?.USD <=
            //       Number(balanceWithdraw?.money_token) &&
            //     handleInvestIternal();
          }}
          style={{
            opacity:
              !loadingSub &&
              amount &&
              iTonPrice &&
              Number(amount) / iTonPrice <= Number(balanceWithdraw?.money_iton)
                ? "1"
                : "0.5",
            // typeInvest === 1
            //   ? amount &&
            //     amount >= 100 &&
            //     Number(amount) / TokenInfo?.prices?.USD <=
            //       Number(tonBalance.toString())
            //     ? "1"
            //     : "0.5"
            //   : !loadingSub &&
            //     amount &&
            //     amount >= 100 &&
            //     Number(amount) / TokenInfo?.prices?.USD <=
            //       Number(balanceWithdraw?.money_token)
            //   ? "1"
            //   : "0.5",
          }}
        >
          {" "}
          {loadingSub ? "Loading..." : "Staking"}
        </Submit>
      </StakeTONConten>
    </StakeTON>
  );
};

export default StakeTon;
