import { Link, NavLink } from "react-router-dom";
import { DragSpan, SidebarHeader } from "./style";
import { NavData, NavMore } from "../Contant";
import { useContext, useEffect, useRef, useState } from "react";
import { CollapseButton, PriceCoin, SideBarMain } from "../../layout/stylednew";
import "../../layout/style.css";
import { useBalance } from "../../hook/useTonBalance";
import { BoxChangeValue } from "../../pages/Dashboard/styled";
import { RootState, dispatch, useSelector } from "../../store/reDucStore";
import { useTonAddress, useTonWallet } from "@tonconnect/ui-react";
import { getTokenInfo } from "../../store/Slices/Token";
import Arrow from "../Arow";
import { ContextProviderWrapper } from "../Context";
import { formatNumber } from "../../utils/formatNumber";
import { getBalances } from "../../store/api/dexApiSlice";
import axios from "axios";

const Sidebar = ({ isMenuMobile, setIsMenuMobile, setOpenSiderBar }: any) => {
  const [idShow, setIsShow] = useState<any>(false);
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const wallet = useTonWallet();
  const [tonBalance, setTonBalance] = useState<any>(0);
  const getBalance = async () => {
    await axios
      .get(`https://tonapi.io/v2/accounts/${wallet?.account?.address}`)
      .then((response) => {
        console.log("tonBalance", response?.data?.balance);
        setTonBalance(response?.data?.balance || 0);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // const { tonBalance }: any = useBalance();
  const address = useTonAddress();
  const TokenInfo = useSelector((state: RootState) => state.token.TokenInfo);

  useEffect(() => {
    if (localStorage.getItem("token") && address) {
      dispatch(getTokenInfo());
      getBalance();
    }
  }, [localStorage.getItem("token"), address]);
  useEffect(() => {
    setOpenSiderBar(idShow);
  }, [idShow]);

  const sidebarRef: any = useRef<HTMLDivElement>(null);

  const handleDrag = () => {
    if (sidebarRef.current) {
      const transform = sidebarRef.current.style.transform;
      const translateY = transform.match(/translateY\(([-0-9.]+)px\)/);
      if (translateY && translateY[1]) {
        const translateYValue = parseFloat(translateY[1]);
        if (translateYValue > 160) {
          setIsMenuMobile(false);
        } else {
          setIsMenuMobile(true);
        }
      }
    }
  };

  return (
    <SideBarMain
      ref={sidebarRef}
      onDrag={isMenuMobile ? handleDrag : undefined}
      style={{
        transition: "all .3s linear",
      }}
      drag={!isDesktop && "y"}
      dragPropagation
      dragElastic={0}
      collapse={isDesktop && idShow}
      dragConstraints={{
        top: 0,
        bottom: 500,
      }}
      className="main-sidebar"
      IsMenuMobile={isMenuMobile}
    >
      {!isDesktop && <DragSpan />}
      <CollapseButton
        collapse={idShow}
        onClick={(e: any) => {
          setIsShow(!idShow);
          e.stopPropagation();
        }}
      >
        <img src="https://app.tonholding.io/assets/IconCollapSidebar.svg" alt="" />
      </CollapseButton>
      <div className="main-menu-row">
        <NavLink to="/">
          <div className="row-logo">
            <SidebarHeader isshow={!idShow}>
              <Link to="">
                <div>
                  <img src="https://app.tonholding.io/assets/LogoTon.svg" alt="" />
                  <img src="https://app.tonholding.io/assets/logoOnlyText.svg" alt="" />
                </div>
              </Link>
            </SidebarHeader>
          </div>
        </NavLink>
        <div className="main-menu">
          <ul className="list-menu">
            {NavData.map((link) => {
              return (
                <li>
                  <NavLink
                    onClick={() => {
                      setIsMenuMobile(false);
                    }}
                    to={link.link}
                    key={link.link}
                  >
                    <img src={link.icon} alt="icon" />
                    <p>{link.title}</p>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="main-menu sub-list">
          <div className="title-menu">
            <div>
              <img src="https://app.tonholding.io/assets/navIcon/MoreIcon.svg" /> <p>More</p>
            </div>
            <img src="https://app.tonholding.io/assets/ArowIcon.svg" alt="" />
          </div>
          <ul className="list-menu ">
            {NavMore.map((link) => {
              return (
                <li>
                  <NavLink to={link.link} key={link.link}>
                    <img src={link.icon} /> <p>{link.title}</p>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="position-banner">
          {idShow ? (
            <></>
          ) : (
            <div className="main-banner">
              <img
                src="https://app.tonholding.io/assets/Siderbar_Panner.png"
                alt=""
                className="menu-banner"
              />
            </div>
          )}
          <div className="main-price">
            <div className="box-price">
              <PriceCoin>
                <div className="price">
                  ~$ {formatNumber(TokenInfo?.prices?.USD?.toFixed(2))}
                </div>
                {/* <div className="img">
                  <img src="https://app.tonholding.io/assets/CoinIcon.png" alt="" />
                </div> */}
              </PriceCoin>
              {TokenInfo?.diff_24h?.USD &&
                TokenInfo?.diff_24h?.USD !== "0%" && (
                  <BoxChangeValue
                    color={
                      TokenInfo?.diff_24h?.USD?.trim()?.startsWith("−")
                        ? "#f13617"
                        : "#17F147"
                    }
                    bg={
                      TokenInfo?.diff_24h?.USD?.trim()?.startsWith("−")
                        ? "rgb(194 76 45 / 15%)"
                        : "rgba(45, 194, 78, 0.15)"
                    }
                    rotate={TokenInfo?.diff_24h?.USD?.trim()?.startsWith("−")}
                  >
                    <Arrow
                      color={
                        TokenInfo?.diff_24h?.USD?.trim()?.startsWith("−")
                          ? "#f13617"
                          : "#17F147"
                      }
                    />
                    <p>{TokenInfo?.diff_24h?.USD}</p>
                  </BoxChangeValue>
                )}
            </div>
          </div>
          {idShow ? (
            <></>
          ) : (
            <div className="main-social">
              <div className="item">
                <a
                  href="https://t.me/tonholding_channel"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="https://app.tonholding.io/assets/SocicalIcon/tele.svg" alt="" />
                </a>
              </div>
              <div className="item">
                <a
                  href="https://t.me/tonholding_chat"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="https://app.tonholding.io/assets/SocicalIcon/tele.svg" alt="" />
                </a>
              </div>
              {/* <div className="item">
                <a
                  href="https://x.com/TONHolding"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="https://app.tonholding.io/assets/SocicalIcon/tw.svg" alt="" />
                </a>
              </div> */}
              <div className="item">
                <a href="/" target="_blank" rel="noreferrer">
                  <img src="https://app.tonholding.io/assets/SocicalIcon/yt.svg" alt="" />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </SideBarMain>
  );
};
export default Sidebar;
//
