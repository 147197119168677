import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ENDPOINT, { PRODUCT } from "../../services/Endpoint";
import type {
  FulfilledAction,
  PendingAction,
  RejectedAction,
} from "../utils/utils";
import { instance } from "../instance";

const initialState: any = {
  WidthrawList: null,
  settingList: null,
  loading: false,
  balanceWithdraw: null,
  DEPOSITList: {data:[]},
  CONVERTList: {data:[]},
  TRANSFERList: {data:[]},
};
export const getWidthrawList = createAsyncThunk(
  `${PRODUCT}/getWidthrawList`,
  async (thunkAPI) => {
    try {
      const response = await instance.get(ENDPOINT.withdrawList, {});
      return response;
    } catch (ex: any) {
      return ex;
    }
  }
);

export const getDEPOSITList = createAsyncThunk(
  `${PRODUCT}/getDEPOSITList`,
  async (thunkAPI) => {
    try {
      const response = await instance.get(ENDPOINT.DEPOSITList, {});
      return response;
    } catch (ex: any) {
      return ex;
    }
  }
);
export const getCONVERTList = createAsyncThunk(
  `${PRODUCT}/getCONVERTList`,
  async (thunkAPI) => {
    try {
      const response = await instance.get(ENDPOINT.CONVERTList, {});
      return response;
    } catch (ex: any) {
      return ex;
    }
  }
);
export const getTRANSFERList = createAsyncThunk(
  `${PRODUCT}/getTRANSFERList`,
  async (thunkAPI) => {
    try {
      const response = await instance.get(ENDPOINT.TRANSFERList, {});
      return response;
    } catch (ex: any) {
      return ex;
    }
  }
);

export const WidthrawSubmit = createAsyncThunk(
  `${PRODUCT}/WidthrawSubmit`,
  async (param, thunkAPI) => {
    try {
      const response = await instance.post(ENDPOINT.withdrawSubmit, param, {});
      return response;
    } catch (ex: any) {
      return ex;
    }
  }
);

export const getBalance = createAsyncThunk(
  `${PRODUCT}/WidthrawgetBalance`,
  async (param, thunkAPI) => {
    try {
      const response = await instance.get(ENDPOINT.withdrawGetBalance, {});
      return response;
    } catch (ex: any) {
      return ex;
    }
  }
);
export const getFee = createAsyncThunk(
  `${PRODUCT}/getFee`,
  async (param, thunkAPI) => {
    try {
      const response = await instance.get(ENDPOINT.fee, {});
      return response;
    } catch (ex: any) {
      return ex;
    }
  }
);

export const TranferSubmit = createAsyncThunk(
  `${PRODUCT}/TranferSubmit`,
  async (param, thunkAPI) => {
    try {
      const response = await instance.post(ENDPOINT.transfer, param);
      return response;
    } catch (ex: any) {
      return ex;
    }
  }
);

export const getValueWidthrawByDay = createAsyncThunk(
  `${PRODUCT}/ValueWidthrawByDay`,
  async () => {
    try {
      const response = await instance.get(ENDPOINT.valueByDay);
      return response;
    } catch (ex: any) {
      return ex;
    }
  }
);

const WidthrawSlice = createSlice({
  name: "Widthraw",
  initialState,
  reducers: {
    //
  },
  extraReducers(builder) {
    builder
      .addCase(getWidthrawList.fulfilled, (state, action) => {
        if (action.payload?.status === 200) {
          state.WidthrawList = action.payload.data;
        } else {
          state.WidthrawList = null;
          // window.location.reload();
        }
      })
      .addCase(getDEPOSITList.fulfilled, (state, action) => {
        if (action.payload?.status === 200) {
          if (action.payload?.data?.data) {
            state.DEPOSITList.data = action.payload.data.data.map((data: any) => {
              return {
                history_id: data?.deposit_history_id,
                request_id: "--",
                user_id: data?.current_user,
                tx_hash: data?.tx_hash,
                amount: data?.amount,
                remark: "DEPOSIT",
                from: "TON",
                to: "",
                fee: 0.25,
                receive: 4.75,
                status: data?.status,
                created_at: data?.created_at,
                updated_at: data?.updated_at,
              };
            });
          }
        } else {
          state.DEPOSITList = null;
          // window.location.reload();
        }
      })
      .addCase(getCONVERTList.fulfilled, (state, action) => {
        if (action.payload?.status === 200) {
          if (action.payload?.data?.data) {
            state.CONVERTList.data = action.payload.data.data.map((data: any) => {
              return {
                history_id: data?.transaction_id,
                request_id: "--",
                user_id: data?.current_user,
                tx_hash: data?.tx_id,
                amount: data?.token_value,
                remark: data?.type,
                from: "TON",
                to: "",
                fee: 0.25,
                receive: 4.75,
                status: "SUCCSESS",
                created_at: data?.created_at,
                updated_at: data?.updated_at,
              };
            });
          }
        } else {
          state.CONVERTList = null;
          // window.location.reload();
        }
      })
      .addCase(getTRANSFERList.fulfilled, (state, action) => {
        if (action.payload?.status === 200) {
          if (action.payload?.data?.data) {
            state.TRANSFERList.data = action.payload.data.data.map((data: any) => {
              return {
                history_id: data?.transaction_id,
                request_id: "--",
                user_id: data?.current_user,
                tx_hash: data?.tx_id,
                amount: data?.token_value,
                remark: data?.type,
                from: "TON",
                to: "",
                fee: 0.25,
                receive: 4.75,
                status: "SUCCSESS",
                created_at: data?.created_at,
                updated_at: data?.updated_at,
              };
            });
          }
        } else {
          state.TRANSFERList = null;
          // window.location.reload();
        }
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        if (action.payload?.status === 200) {
          state.balanceWithdraw = action.payload.data;
        } else {
          state.balanceWithdraw = null;
          // window.location.reload();
        }
      })
      .addCase(getFee.fulfilled, (state, action) => {
        if (action.payload?.status === 200) {
          state.settingList = action.payload.data;
        } else {
          state.balanceWithdraw = null;
          // window.location.reload();
        }
      })
      .addMatcher<PendingAction>(
        (action) => action.type.endsWith("/pending"),
        (state, action) => {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      )
      .addMatcher<RejectedAction | FulfilledAction>(
        (action) =>
          action.type.endsWith("/rejected") ||
          action.type.endsWith("/fulfilled"),
        (state, action) => {
          if (
            state.loading &&
            state.currentRequestId === action.meta.requestId
          ) {
            state.loading = false;
            state.currentRequestId = null;
          }
        }
      );
  },
});

const WidthrawReducer = WidthrawSlice.reducer;

export default WidthrawReducer;
