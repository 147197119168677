export const NavData = [
  {
    title: "Dashboard",
    link: "/",
    icon: "https://app.tonholding.io/assets/navIcon/DashboardIcon.svg",
  },
  {
    title: "Staking",
    link: "/staking",
    icon: "https://app.tonholding.io/assets/navIcon/stakingIcon.svg",
  },
  {
    title: "Farming",
    link: "/farming",
    icon: "https://app.tonholding.io/assets/navIcon/farming.svg",
  },
  {
    title: "Network",
    link: "/network",
    icon: "https://app.tonholding.io/assets/navIcon/networdIcon.svg",
  },
  {
    title: "Wallet",
    link: "/Wallet",
    icon: "https://app.tonholding.io/assets/navIcon/withdrawIcon.svg",
  },
  {
    title: "Ranking Bonus",
    link: "/bonus",
    icon: "https://app.tonholding.io/assets/navIcon/bonusIcon.svg",
  },
  {
    title: "Affiliate",
    link: "/affiliate",
    icon: "https://app.tonholding.io/assets/navIcon/affiliateIcon.svg",
  },
  {
    title: "History",
    link: "/history",
    icon: "https://app.tonholding.io/assets/navIcon/historyIcon.svg",
  },
];
export const NavMore = [
  {
    title: "Tokenomics",
    link: "/tokenomics",
    icon: "https://app.tonholding.io/assets/navIcon/tokennomic.svg",
  },
  {
    title: "Roadmap",
    link: "/roadmap",
    icon: "https://app.tonholding.io/assets/navIcon/roadmap.svg",
  },
  // {
  //   title: "Docs",
  //   link: "/Docs",
  //   icon: "https://app.tonholding.io/assets/navIcon/docs.svg",
  // },
];
export const LangData = [
  {
    value: "",
    label: "English",
    icon:"https://app.tonholding.io/assets/flast/United.png"
  },
  {
    value: "",
    label: "English",
    icon:"https://app.tonholding.io/assets/flast/United.png"
  },
  {
    value: "",
    label: "English",
    icon:"https://app.tonholding.io/assets/flast/United.png"
  },
  {
    value: "",
    label: "English",
    icon:"https://app.tonholding.io/assets/flast/United.png"
  },
  {
    value: "",
    label: "English",
    icon:"https://app.tonholding.io/assets/flast/United.png"
  },
  {
    value: "",
    label: "English",
    icon:"https://app.tonholding.io/assets/flast/United.png"
  },
];
