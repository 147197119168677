import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../Provider/MenuProvider";
import { useTonAddress } from "@tonconnect/ui-react";
import { NetworkInner, NetworkWapper, PannerNetword } from "../Network/style";
import {
  BoxInputTyprNumber,
  BoxNotification,
  StakeTONConten,
  StakingTitle,
} from "../Staking/styled";
import {
  BalanceContainer,
  BalanceItonBox,
  BalanceTonBox,
  BoxAmountBalance,
  BoxAmountITon,
  BoxAmountTon,
  BoxBlanceAss,
  BoxNumberAmount,
  BtnFarimg,
  BtnFarm,
  CardDetail,
  FarmedBox,
  FarmedConten,
  FarmingBox,
  FarmingContent,
  FramedDetail,
  TonMes,
} from "./style";
import { BoxTitleRadius } from "../Dashboard/styled";
import NotiIcon from "../../assets/Staking/Noti.svg";
import { useDispatch } from "../../store/reDucStore";
import { getBalance } from "../../store/Slices/Withdraw";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { instance } from "../../store/instance";
import axios from "axios";
import toast from "react-hot-toast";
import { getTokenInfo } from "../../store/Slices/Token";
import ModalConfirm from "../Withdraw/modalConfirm";

const Farming = () => {
  const { setTitle, setDes, handleOpenCustom, isOpen }: any =
    useContext(MenuContext);
  const address = useTonAddress();
  const [amountStake, setAmountStake] = useState(0);
  const [amountTon, setAmountTon] = useState(0);
  const [amountiTon, setAmountiTon] = useState(0);
  const [farmInfo, setFarmInfo]: any = useState();
  const [loading, setLoading]: any = useState(false);

  const balanceWithdraw = useSelector(
    (state: RootState) => state.withdraw.balanceWithdraw
  );
  const TokenInfo = useSelector((state: RootState) => state.token.TokenInfo);
  useEffect(() => {
    setTitle("Farming");
  }, []);
  const dispatch = useDispatch();
  const handleGetFarmInfo = async () => {
    const Res = await instance.get("farming/info", {
      headers: {
        Authorization: localStorage.getItem("token") || null,
      },
    });
    console.log("Res---------------------------", Res);
    if (Res.status === 200) {
      setFarmInfo(Res.data);
    }
  };
  useEffect(() => {
    if (localStorage.getItem("token") && address) {
      dispatch(getTokenInfo());
      dispatch(getBalance());
      handleGetFarmInfo();
    }
  }, [localStorage.getItem("token"), address]);

  const handleFarm = async () => {
    if (!amountStake) return;
    if (amountStake < 3000) {
      toast.error("Farm a minimum of $3,000.");
      return;
    }
    if (
      TonActive.key === "money_token" &&
      amountTon > balanceWithdraw?.money_token
    ) {
      toast.error("Balance TON Not Enough");
      return;
    }
    if (
      TonActive.key === "money_token_internal" &&
      amountTon > balanceWithdraw?.money_token_internal
    ) {
      toast.error("Balance TON Not Enough");
      return;
    }
    if (amountiTon > balanceWithdraw?.money_iton) {
      toast.error("Balance iTON Not Enough");
      return;
    }
    const handleConfirm = async () => {
      setLoading(true);
      const Res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}farming/farm`,
        {
          amount_usd: amountStake,
          use_ton_internal: TonActive.key === "money_token_internal",
        },
        {
          headers: {
            Authorization: localStorage.getItem("token") || null,
          },
        }
      );
      if (Res.status === 200) {
        if (Res?.data?.is_success) {
          toast.success(Res?.data?.message);
          setAmountStake(0);
          setAmountTon(0);
          setAmountiTon(0);
          dispatch(getBalance());
          handleGetFarmInfo();
          setLoading(false);
        } else {
          toast.error(Res?.data?.message);
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error("There is a slight issue. Could you please try again?");
      }
    };
    handleOpenCustom(
      <ModalConfirm
        handleConfirm={handleConfirm}
        tabsActive={-3}
        amount={amountStake}
      />
    );
  };
  const [showList, setShowList] = useState(false);
  const [TonActive, setTonActive] = useState(ListWallet[0]);

  return (
    <>
      <NetworkWapper>
        <StakingTitle typeRever={true}>
          <FarmingBox>
            <BoxTitleRadius>
              <h3>Farming</h3>
            </BoxTitleRadius>
            <StakeTONConten>
              <FarmingContent>
                <BalanceContainer>
                  <BalanceTonBox
                    style={{
                      borderRadius: "10px",
                      position: "relative",
                      zIndex: 1,
                    }}
                    onClick={() => {
                      setShowList(!showList);
                    }}
                  >
                    <BoxBlanceAss>
                      <img src="/assets/ton_symbol.svg" />
                      <div>
                        <h3>{TonActive.title}</h3>
                        <p>
                          {Number(
                            balanceWithdraw?.[TonActive.key] || 0
                          ).toFixed(2) || 0}
                        </p>
                      </div>
                    </BoxBlanceAss>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {/* <BtnFarimg href="/Wallet/deposit">
                        <img src="/assets/Arrow-Right.svg" />
                        <p>Deposit</p>
                      </BtnFarimg> */}
                      <BtnFarimg href="#">
                        <img src="/assets/Arrow-Right.svg" />
                        <p>Deposit</p>
                      </BtnFarimg>
                      <img
                        style={{ filter: "brightness(5)" }}
                        src="https://app.tonholding.io/assets/arrowBlue.svg"
                        alt=""
                      />
                    </div>
                    <ul
                      style={{
                        transform: `translateY(${showList ? 100 : 0}%)`,
                        opacity: showList ? 1 : 0,
                      }}
                    >
                      {ListWallet.map(
                        (wallet: any) =>
                          wallet.key !== TonActive.key && (
                            <li
                              onClick={() => {
                                setTonActive(wallet);
                              }}
                            >
                              <BoxBlanceAss>
                                <img src="/assets/ton_symbol.svg" />
                                <div>
                                  <h3>{wallet.title}</h3>
                                  <p>
                                    {Number(
                                      balanceWithdraw?.[wallet.key] || 0
                                    ).toFixed(2) || 0}
                                  </p>
                                </div>
                              </BoxBlanceAss>
                            </li>
                          )
                      )}
                    </ul>
                  </BalanceTonBox>
                  <BalanceItonBox style={{ borderRadius: "10px" }}>
                    <BoxBlanceAss>
                      <img src="/assets/iton_symbol.svg" />
                      <div>
                        <h3>iTON Balance</h3>
                        <p>
                          {Number(balanceWithdraw?.money_iton || 0).toFixed(
                            2
                          ) || 0}
                        </p>
                      </div>
                    </BoxBlanceAss>
                    <BtnFarimg
                      href="https://exchange.iton.co/buy-ido"
                      target="_blank"
                    >
                      <img src="/assets/Arrow-Right.svg" />
                      Buy More
                    </BtnFarimg>
                  </BalanceItonBox>
                </BalanceContainer>
                <BoxInputTyprNumber>
                  <h6>Amount ($)</h6>
                  <div>
                    <input
                      type="number"
                      pattern="0.00"
                      min={0}
                      value={amountStake || ""}
                      onChange={(e: any) => {
                        setAmountStake(Number(e.target.value));
                        setAmountTon(
                          TokenInfo?.prices?.USD && e.target.value
                            ? Number(e.target.value) /
                                2 /
                                Number(TokenInfo?.prices?.USD)
                            : 0
                        );
                        setAmountiTon(
                          e.target.value
                            ? Number(e.target.value) / 2 / 0.126
                            : 0
                        );
                      }}
                    />
                    <button>Max</button>
                  </div>
                </BoxInputTyprNumber>
                <BoxNotification>
                  <div>
                    <img src={NotiIcon} alt="icon" />
                  </div>
                  <p>Start by staking at least $3,000 worth of $TON</p>
                </BoxNotification>
              </FarmingContent>
              <BoxAmountBalance>
                <BoxAmountTon>
                  <TonMes>
                    <h1>TON (50%)</h1>
                    <p
                      style={{
                        display:
                          TonActive.key === "money_token" &&
                          amountTon > balanceWithdraw?.money_token
                            ? "block"
                            : TonActive.key === "money_token_internal" &&
                              amountTon > balanceWithdraw?.money_token_internal
                            ? "block"
                            : "none",
                      }}
                    >
                      Insufficient Balance
                    </p>
                  </TonMes>
                  <BoxNumberAmount>
                    <p>{amountTon.toFixed(2)}</p>
                    <img src="/assets/ton_symbol.svg" />
                  </BoxNumberAmount>
                </BoxAmountTon>
                <BoxAmountITon>
                  <TonMes>
                    <h1 style={{ color: "#000" }}>iTON (50%)</h1>
                    <p
                      style={{
                        display:
                          amountiTon > balanceWithdraw?.money_iton
                            ? "block"
                            : "none",
                      }}
                    >
                      Insufficient Balance
                    </p>
                  </TonMes>
                  <BoxNumberAmount>
                    <p style={{ color: "#000" }}>{amountiTon.toFixed(2)}</p>
                    <img src="/assets/iton_symbol.svg" />
                  </BoxNumberAmount>
                </BoxAmountITon>
              </BoxAmountBalance>
              <BtnFarm
                onClick={() => {
                  !loading && amountStake && handleFarm();
                }}
                style={{
                  opacity: loading || !amountStake ? 0.5 : 1,
                }}
              >
                {loading ? "Loading..." : "Farming"}
              </BtnFarm>
            </StakeTONConten>
          </FarmingBox>
          <FarmedBox>
            <BoxTitleRadius>
              <h3>Your Farmed</h3>
            </BoxTitleRadius>
            <FarmedConten>
              <FramedDetail>
                <img src="/assets/FramIcon1.svg" />
                <CardDetail>
                  <p>Total Farming</p>
                  <h3>
                    <p>
                      $
                      {farmInfo?.amount_usd
                        ? Number(farmInfo?.amount_usd).toFixed(2)
                        : 0.0}
                    </p>
                  </h3>
                </CardDetail>
              </FramedDetail>
              <FramedDetail>
                <img src="/assets/FramIcon2.svg" />
                <CardDetail>
                  <p>ITON Farm</p>
                  <h3>
                    <p>
                      {farmInfo?.amount_iton
                        ? Number(farmInfo?.amount_iton).toFixed(2)
                        : 0.0}
                      <img src="/assets/iton_symbol.svg" />
                    </p>
                  </h3>
                </CardDetail>
              </FramedDetail>
              <FramedDetail>
                <img src="/assets/FramIcon1.svg" />
                <CardDetail>
                  <p>Your Earning</p>
                  <h3>
                    <p>
                      {farmInfo?.received_profit_token
                        ? Number(farmInfo?.received_profit_token).toFixed(2)
                        : 0.0}
                      <img src="/assets/ton_symbol.svg" />
                    </p>
                  </h3>
                </CardDetail>
              </FramedDetail>
              <FramedDetail>
                <img src="/assets/FramIcon2.svg" />
                <CardDetail>
                  <p>Time Farming</p>
                  <h3>
                    <p>
                      {farmInfo?.profit_date
                        ? calculateDaysDifference(farmInfo?.profit_date)
                        : 0}{" "}
                      Days
                    </p>
                  </h3>
                </CardDetail>
              </FramedDetail>
            </FarmedConten>
          </FarmedBox>
        </StakingTitle>
      </NetworkWapper>
    </>
  );
};
export default Farming;

const calculateDaysDifference = (startDate: string) => {
  const currentDate = new Date();
  const start = new Date(startDate);
  const timeDifference = currentDate.getTime() - start.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  return Math.floor(daysDifference);
};
const ListWallet = [
  {
    title: "TON Mainnet Balance",
    key: "money_token",
  },
  {
    title: "TON Internal Balance",
    key: "money_token_internal",
  },
];
