import { BoxNotification, Submit } from "../../Staking/styled";
import { WithdrawInput, WithdrawInputAmount } from "../style";
import NotiIcon from "../../../assets/Staking/CompoundBadge.svg";
import axios from "axios";
import { Coins } from "ton3-core";
import { useEffect, useState } from "react";
import {
  useTonAddress,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import { convertFixed } from "../../../utils/formatNumber";
import toast from "react-hot-toast";

const DepositBox = ({
  amount,
  setAmount,
  fee,
  setFee,
  handleDeposit,
  loadingSub,
}: any) => {
  const transactionRequestBody = {
    type: "transfer",
    expires_sec: Math.floor(Date.now() / 1000) + 3600,
    response_options: {
      broadcast: true,
      return_url: "https://app.tonholding.io/",
      callback_url: "http://192.168.1.225:8888/.netlify/functions/getPrice",
    },
    params: {
      to: `${process.env.REACT_APP_BACKEND_ROOT_WALLET}`,
      amount: "1000000000",
    },
  };

  const transactionRequest = {
    version: "0", // Loại yêu cầu không ký
    body: transactionRequestBody,
  };

  // Chuyển JSON thành Base64URL
  function encodeToBase64URL(obj: any) {
    const jsonString = JSON.stringify(obj);
    const base64 = btoa(jsonString);
    return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
  }

  const base64TransactionRequest = encodeToBase64URL(transactionRequest);

  // Tạo liên kết toàn cầu
  const universalLink = `https://app.tonkeeper.com/v1/txrequest-inline/${base64TransactionRequest}`;
  console.log("Liên kết TR nhúng:", universalLink);
  const [tonConnectUI] = useTonConnectUI();
  const address = useTonAddress();
  const wallet = useTonWallet();
  const [tonBalance, setTonBalance] = useState<any>(0);
  useEffect(() => {
    getBalance();
  }, [wallet?.account?.address]);

  const getBalance = async () => {
    await axios
      .get(`https://tonapi.io/v2/accounts/${wallet?.account?.address}`)
      .then((response: any) => {
        setTonBalance(Coins.fromNano(response?.data?.balance || 0));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  return (
    <WithdrawInput>
      <WithdrawInputAmount>
        <BoxNotification>
          <div
            style={{
              margin: "0px",
              alignItems: "center",
              borderRadius: 0,
              background: "transparent",
              width: "auto",
              aspectRatio: "auto",
            }}
          >
            <span style={{ margin: 0 }}>TON Coin</span>
          </div>
          <h6 style={{display:"flex" , alignItems:"center" , gap:5}} >
            {convertFixed(Number(tonBalance))}{"  "}
            <img
              src={"https://app.tonholding.io/assets/ton_symbol.svg"}
              alt="icon"
            />
          </h6>
        </BoxNotification>
        <h3>Amount</h3>
        <div>
          <input
            type="number"
            pattern="0.00"
            value={amount ? amount : ""}
            onChange={(e: any) => {
              e.target.value >= 0 && setAmount(e.target.value);
            }}
          />
          <button onClick={()=>{setAmount(tonBalance)}} >Max</button>
        </div>
        {/* <a href={universalLink} >universalLink</a> */}
        <Submit
          onClick={()=>{Number(amount) > tonBalance ? toast.error("Balance Not Enough") : handleDeposit()}}
          style={{
            opacity: amount ? "1" : "0.5",
          }}
        >
          {" "}
          {loadingSub ? "Loading..." : "Deposit"}
        </Submit>
      </WithdrawInputAmount>
    </WithdrawInput>
  );
};
export default DepositBox;
