import { useSelector } from "react-redux";
import { BoxNotification, Submit } from "../../Staking/styled";
import { BoxSelectWallet, WithdrawInput, WithdrawInputAmount } from "../style";
import { formatNumber } from "../../../utils/formatNumber";
import NotiIcon from "../../../assets/Staking/CompoundBadge.svg";
import { useState } from "react";

const TransferBox = ({
  amount,
  setAmount,
  fee,
  setFee,
  handleWithdraw,
  loadingSub,
  setRevierAddress,
  revierAddress,
}: any) => {
  const balanceWithdraw = useSelector(
    (state: any) => state.withdraw.balanceWithdraw
  );
  const TokenInfo = useSelector((state: any) => state.token.TokenInfo);
  const Fee = useSelector((state: any) => state.withdraw.settingList);
  const [activeWallet, setActiveWallet] = useState(ListWallet[0]);
  const [iShow, setIsShow] = useState(false);
  return (
    <WithdrawInput>
      <WithdrawInputAmount>
        <h3>
          Amount
          <BoxSelectWallet
            onClick={() => {
              setIsShow(!iShow);
            }}
          >
            {/* <p>Funding source : </p> */}
            <div>
              <p>{activeWallet.title}</p>
              <img src="https://app.tonholding.io/assets/arrowBlue.svg" />
              <ul
                style={{
                  opacity: iShow ? 1 : 0,
                  pointerEvents: iShow ? "auto" : "none",
                }}
              >
                {ListWallet.map((wallet: any) =>
                  wallet.key !== activeWallet.key ? (
                    <li
                      onClick={() => {
                        setActiveWallet(wallet);
                      }}
                    >
                      {wallet.title}
                    </li>
                  ) : (
                    <></>
                  )
                )}
              </ul>
            </div>
          </BoxSelectWallet>{" "}
        </h3>
        <div>
          <input
            type="number"
            pattern="0.00"
            value={amount ? amount : ""}
            onChange={(e: any) => {
              e.target.value >= 0 && setAmount(e.target.value);
            }}
          />
          <button
            onClick={() => {
              activeWallet.key === "TON_Mainnet"
                ? setAmount(
                    Math.floor(Number(balanceWithdraw?.money_token)) || 0
                  )
                : setAmount(
                    Math.floor(Number(balanceWithdraw?.money_token_internal)) ||
                      0
                  );
            }}
          >
            Max
          </button>
        </div>
        <p>
          ~$
          {TokenInfo?.prices?.USD
            ? formatNumber(
                (Number(amount) * TokenInfo?.prices?.USD)?.toFixed(2)
              )
            : 0}{" "}
        </p>
        <h3>Wallet Address:</h3>
        <div style={{ marginBottom: 8 }}>
          <input
            type="text"
            pattern="0.00"
            value={revierAddress}
            onChange={(e: any) => {
              setRevierAddress(e.target.value);
            }}
          />
        </div>
        <BoxNotification>
          <div>
            <img src={NotiIcon} alt="icon" />
            <p>Fee Transfer</p>
          </div>
          <h6>
            <span>{fee}%</span>
            {`(~$${
              fee
                ? `${
                    TokenInfo?.prices?.USD
                      ? formatNumber(
                          (
                            (Number(amount) * TokenInfo?.prices?.USD * fee) /
                            100
                          )?.toFixed(2)
                        )
                      : 0
                  }`
                : "0"
            })`}
          </h6>
        </BoxNotification>
        <h3>Minimum Transfer $10</h3>
        <Submit
          onClick={() => {
            !loadingSub && handleWithdraw(activeWallet);
          }}
          style={{
            opacity:
              amount && Number(amount) * TokenInfo?.prices?.USD >= 10
                ? activeWallet.key === "TON_Mainnet" &&
                  Number(amount) <= balanceWithdraw?.money_token
                  ? "1"
                  : activeWallet.key === "TON_Internal" &&
                    Number(amount) <= balanceWithdraw?.money_token_internal
                  ? "1"
                  : "0.5"
                : "0.5",
          }}
        >
          {loadingSub ? "Loading..." : "Transfer"}
        </Submit>
      </WithdrawInputAmount>
    </WithdrawInput>
  );
};
export default TransferBox;

const ListWallet = [
  {
    title: "TON Mainnet",
    key: "TON_Mainnet",
  },
  {
    title: "TON Internal",
    key: "TON_Internal",
  },
];
