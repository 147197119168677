const ENDPOINT = {
  dashBoardInfo: "dashboard/statistic",
  networdInfo:"networks",
  TokenInfo:"tokens/price",
  submitInvest:"investments",
  nodeTree:"networks/tree",
  InvestInfo:"investments/statistic",
  transactions:"transactions",
  getAdress:"investments/address",
  withdrawList:"wallets/histories?filter[remark]=WITHDRAW&sort[field]=created_at&sort[order]=DESC",
  DEPOSITList:"deposit/list",
  CONVERTList:"transactions?filter[type]=CONVERT&pagination[page]=1&pagination[perPage]=30&sort[field]=created_at&sort[order]=DESC",
  TRANSFERList:"transactions?filter[type]=TRANSFER&pagination[page]=1&pagination[perPage]=30&sort[field]=created_at&sort[order]=DESC",
  withdrawSubmit:"wallets/withdrawal",
  withdrawGetBalance :"users/balances",
  profile:"users/me",
  fee:"settings?&pagination[page]=1&pagination[perPage]=150",
  bonusBalance:"rankings/balances",
  afiliteList:"affiliate",
  investStatus: "investments/status",
  transfer:"transfer",
  valueByDay:"wallets/withdrawal/available"
};
export default ENDPOINT;
export const PRODUCT = 'TonHolding';