import { useContext, useEffect } from "react";
import styled from "styled-components";
import { MenuContext } from "../../Provider/MenuProvider";
import {
  ModalContent,
  WalletAddressInfo,
  WalletInfoContent,
} from "../../components/ModalDisConnect/styled";
import Bg from "../../assets/Dashboard/ThumBottom.png";

const ModalConfirm = ({
  handleConfirm,
  tabsActive,
  amount,
  address,
  setLoadingSub,
}: any) => {
  const { handleCloseCustom } = useContext(MenuContext);
  return (
    <ModalLayout>
      <ModalContainer
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <WalletInfoContent>
          <ModalContent>
            <h3 style={{paddingLeft: 0}} >Confirmation</h3>
            <WalletInfo>
              <ModalIcon>
                <img src="./assets/IconConfirm.png" />
              </ModalIcon>
              <h6>Are you Sure?</h6>
              <h5>Confirm by click “Confirm & Stake” button below.</h5>
              <BoxModal>
                <h4>Staking Amount</h4>
                <h2>
                  {Number(amount)?.toFixed(3) || 0} <img src="/assets/iton_symbol.svg" />
                </h2>
              </BoxModal>
            </WalletInfo>
            <BtnGroup>
              <BtnCancel
                onClick={() => {
                  handleCloseCustom();
                }}
              >
                <p>Cancel</p>
              </BtnCancel>
              <BtnCancel
                onClick={async () => {
                  handleConfirm();
                  handleCloseCustom();
                }}
              >
                <p>Confirm & Stake</p>
              </BtnCancel>
            </BtnGroup>
          </ModalContent>
        </WalletInfoContent>
      </ModalContainer>
    </ModalLayout>
  );
};
export default ModalConfirm;
const ModalLayout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const ModalContainer = styled.div`
  border-radius: 20px;
  width: 100%;
  max-width: 420px;
`;
export const WalletInfo = styled.div`
  border-radius: 8px;
  background: var(--Gray-100, rgba(27, 28, 32, 1));
  width: 100%;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    max-width: 75px;
    border-radius: 14px;
    border: 1px solid #ffffff20;
    margin: auto;
    box-shadow: 0px 0px 42.088px 0px rgba(130, 224, 255, 0.25),
      2.775px 3.7px 3.7px 0px rgba(106, 106, 106, 0.48) inset;
  }
  p {
    text-align: left;
  }
  & > p {
    color: #80c2ff;
    font-family: "anek-odia-400-normal";
    font-size: 14px;
    letter-spacing: 0.14px;
    margin-bottom: 5px;
  }
  h6 {
    font-size: 21px;
    color: #fff;
    text-align: center;
    font-family: AnekLatin-Regular;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 96%; /* 20.16px */
    text-transform: capitalize;
    margin-bottom: 5px;
  }
  h5 {
    color: #6b6b6b;
    text-align: center;
    font-family: AnekLatin-Regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 13px */
    letter-spacing: -0.13px;
    margin-bottom: 16px;
  }
`;
const ModalIcon = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
  &::after {
    width: 60%;
    content: "";
    position: absolute;
    background-image: url(${Bg});
    background-size: cover;
    background-position: center;
    aspect-ratio: 445 / 125;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%) translateY(0%);
  }
`;
const BoxModal = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: #303239;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  align-self: stretch;
  h4 {
    color: #fff;
    font-family: AnekLatin-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
    letter-spacing: 0.16px;
  }
  h2 {
    color: #fff;
    font-family: AnekLatin-Regular;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 100% */
    letter-spacing: -1.44px;
    display: flex;
    align-items: center;
    gap: 8px;
    img{
      width: 32px;
      border: none;
    }
  }
`;
const BtnGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
`;
const BtnCancel = styled.button`
  border-radius: 34px;
  background: var(--Blue-Base, #0085FF);
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 2px);
  border: none;
  cursor: pointer;
  p {
    line-height: 100%;
  }
  &:first-child {
    background: var(--Gray-80, #444750);
  }
`;