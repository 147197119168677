import styled from "styled-components";
import CloseIcon from "../../assets/close.svg";
import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../Provider/MenuProvider";
import { useTonAddress, useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
// @ts-ignore
import { ModalContent, WalletAddressInfo, WalletBalance, WalletInfo, WalletInfoContent } from "./styled";
import { useBalance } from "../../hook/useTonBalance";
import { convertFixed } from "../../utils/formatNumber";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import axios from "axios";
import { Coins } from "ton3-core";

const ModalDisConnect = () => {
  const { handleCloseCustom } = useContext(MenuContext);
  const [tonConnectUI] = useTonConnectUI();
  const address = useTonAddress();
  const wallet = useTonWallet();
  const [tonBalance , setTonBalance] = useState<any>(0);
  useEffect(()=>{
    getBalance()
  },[])
  const getBalance = async () => {
    await axios
      .get(`https://tonapi.io/v2/accounts/${wallet?.account?.address}`)
      .then((response:any) => {
        setTonBalance( Coins.fromNano(response?.data?.balance || 0))
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const balanceTexts = tonBalance ? convertFixed(Number(tonBalance)).split(".") : ["--", "--"];
  return (
    <ModalLayout>
      <ModalContainer
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <WalletInfoContent>
          <ModalContent>
            <h3>Your Wallet</h3>
            <WalletInfo>
              <p>
                Wallet Address
              </p>
              <WalletAddressInfo>
                <p>{address.slice(0, 4)}...${address.slice(-4)}</p>
                <CopyToClipboard
                  text={address}
                  onCopy={() => {
                    toast.success("Copied");
                  }}
                >
                  <p><span>Copy</span>
                    <img src="https://app.tonholding.io/assets/icon/copy.svg" alt="copy" />
                  </p>
                </CopyToClipboard>
              </WalletAddressInfo>
              <WalletBalance>
                <p>Total balance</p>
                {tonBalance && <p>
                  {balanceTexts[0] + `.${balanceTexts[1]}`}
                  <img src="https://app.tonholding.io/assets/LogoTon.svg" alt="" />
                </p>}
              </WalletBalance>
              <BtnGroup>
                <BtnCancel
                  onClick={() => {
                    tonConnectUI.disconnect();
                    handleCloseCustom();
                    localStorage.removeItem("token");
                    localStorage.removeItem("ref");
                  }}
                >
                  <p>Disconnect</p>
                </BtnCancel>
                <BtnCancel onClick={handleCloseCustom}>
                  <p>Cancel</p>
                </BtnCancel>
              </BtnGroup>
            </WalletInfo>
          </ModalContent>
        </WalletInfoContent>
      </ModalContainer>
    </ModalLayout>
  );
};
export default ModalDisConnect;
const ModalLayout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const ModalContainer = styled.div`
  border-radius: 20px;
  width: 100%;
  max-width: 420px;
`;
// const ModalContent = styled.div`
//   border-radius: 16px;
//   background: var(--Gray-90, #2d2f35);
//   position: relative;
//   display: flex;
//   padding: 16px;
//   padding-top: 32px;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   margin-top: 4px;
//   width: 100%;
//   gap: 18px;
//   h3 {
//     leading-trim: both;
//     text-edge: cap;
//     font-family: AnekLatin-Regular;
//     font-size: 25px;
//     font-style: normal;
//     font-weight: 800;
//     line-height: normal;
//     background: linear-gradient(90deg, #fff 51.48%, #2d2f35 171.59%);
//     background-clip: text;
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//   }
//   p {
//     color: #dadde6;
//     text-align: center;
//     font-family: AnekLatin-Regular;
//     font-weight: 500;
//     font-size: 17px;
//     line-height: 150%; /* 150% */
//     letter-spacing: 0.268px;
//     max-width: 320px;
//   }
// `;
const BtnGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
`;
const BtnCancel = styled.button`
  border-radius: 34px;
  background: var(--Gray-80, #444750);
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 2px);
  border: none;
  cursor: pointer;
  p {
    line-height: 100%;
  }
  &:first-child {
    background: var(--Blue-Base, #ea2d2d);
  }
`;
const BtnClose = styled.div`
  background: var(--Gray-80, #444750);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
