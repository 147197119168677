import styled from "styled-components";

export const BoxDropList = styled.div<{ isshow?: any }>`
  width: fit-content;
  border-radius: 28px;
  background: #fff;
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  position: relative;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    /* z-index: ${({ isshow }) => (isshow ? -2 : 2)}; */
    z-index: 2;
    cursor: pointer;
  }
  p {
    color: var(--Blue-70, #0059aa);
    leading-trim: both;
    text-edge: cap;
    font-family: "AnekLatin-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 200% */
    position: relative;
    z-index: 1;
  }
  img {
    max-width: 10px;
    transition: all 0.3s linear;
  }
`;
export const ListUl = styled.ul<{ isshow?: any }>`
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  background: #fff;
  gap: 5px;
  opacity: ${({ isshow }) => (isshow ? 1 : 0)};
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  pointer-events:${({ isshow }) => (isshow ? "unset" : "none")};
  padding: 22px;
  z-index: 10;
`;
export const ListElm = styled.li<{ lock?: any }>`
  color: ${({ lock }) => (lock ? "#00000050" : "#000")};
  leading-trim: both;
  text-edge: cap;
  font-family: "AnekLatin-Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  span {
    display: flex;
    padding: 6px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: rgba(26, 41, 99, 0.85);
    color: #7aafff;
    leading-trim: both;
    text-edge: cap;
    font-family: "Plus Jakarta Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
