import styled from "styled-components";
import { BoxTitlePage } from "../Staking/styled";
import { BoxTitleRadius } from "../Dashboard/styled";
import { BlueTable } from "../Bonus/styled";
import { TableContainer } from "../../components/Table";

export const HistoryWapper = styled.div`
  width: 100%;
  padding: 40px 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 1660px;
  margin: 0px auto;
  flex: 1;
  height: 100%;
  ${TableContainer} {
    table {
      td,
      th {
        &:last-child {
          text-align: left;
        }
      }
    }
  }
  @media screen and (max-width: 1040px) {
    padding: 40px 24px 80px;
  }
  @media screen and (max-width: 767px) {
    padding: 25px 15px 100px;
    height: 100vh;
    ${BoxTitlePage} {
      display: none;
    }
    ${BoxTitleRadius} {
      padding-left: 0;
      display: block;
      margin-bottom: 10px;
      h3 {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
    ${BlueTable} {
      max-width: 100%;
    }
    ${TableContainer} {
      background-color: transparent;
      table {
        min-width: auto;
      }
      thead {
        display: none;
      }
      tbody {
        display: block;
        tr {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 10px;
          background: #121315;
          border-radius: 10px;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          td {
            padding: 0;
            &:nth-child(1) {
              width: 50%;
              margin-bottom: 10px;
              & > div {
                display: flex;
                gap: 5px;
                align-items: center;
                & > p {
                  &:nth-child(1) {
                    padding: 4px 12px;
                    border-radius: 35px;
                    background: #5a5f6b;
                    font-size: 13px;
                  }
                }
              }
            }
            &:nth-child(2) {
              width: 50%;
              margin-bottom: 10px;
              & > div {
                margin-left: auto;
                text-align: right;
              }
            }
            &:nth-child(3),
            &:nth-child(4) {
              display: flex;
              align-items: center;
              order: 7;
              width: calc(50% - 3px);
              background-color: red;
              border-radius: 8px;
              background: #2d2f35;
              padding: 8px;
              min-height: 60px;
              & > div {
                & > p {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  &:nth-child(1) {
                    margin-bottom: 7px;
                    color: #878ea0;
                    font-size: 16px;
                    text-transform: capitalize;
                  }
                  &:nth-child(2) {
                    font-size: 16px;
                    color: #dce3eb;
                  }
                }
              }
            }
            &:nth-child(4) {
              margin-left: auto;
            }
            &:nth-child(5) {
              width: 50%;
              order: 2;
              display: none;
              & > div {
                & > p {
                  text-align: right;
                  color: #aad6ff;
                  font-size: 14px;
                  text-transform: capitalize;
                }
              }
            }
            &:nth-child(6) {
              width: 100%;
              order: 4;
              margin-bottom: 10px;
              & > div {
                p {
                  display: flex;
                  justify-content: space-between;
                  span {
                    font-family: "AnekLatin-Regular";
                    text-transform: capitalize;
                    font-size: 16px;
                    &:nth-child(1) {
                      color: #878ea0;
                    }
                    &:nth-child(2) {
                      color: #0085ff;
                    }
                  }
                }
              }
            }
            &:nth-child(7) {
              width: 100%;
              margin-bottom: 10px;
              order: 5;
              & > div {
                p {
                  display: flex;
                  justify-content: space-between;
                  span {
                    font-family: "AnekLatin-Regular";
                    text-transform: capitalize;
                    font-size: 16px;
                    &:nth-child(1) {
                      color: #878ea0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const BoxFillter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgb(45, 47, 53);
  border-radius: 20px;
  padding: 3px;
`;
export const FillterIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
`;
export const BoxTxType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  position: relative;
  min-width: 100px;
  p , li{
    color: #e7e8ec;
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 15px;
    line-height: 110%;
    letter-spacing: 0.14px;
    text-align: center;
    padding:2px;
  }
  ul{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    background: rgb(45, 47, 53);
  }
`