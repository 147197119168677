import CopyToClipboard from "react-copy-to-clipboard";
import { BoxTitleRadius } from "../../Dashboard/styled";
import { BoxElm, BoxElmTable, BoxValueElm, BoxValueGroup, ButtonCopyLink } from "../style";
import toast from "react-hot-toast";
import { BlueTable } from "../../Bonus/styled";
import Table from "../../../components/Table";
import { RootState, useSelector } from "../../../store/reDucStore";
import CardIcon1 from "../../../assets/Dashboard/CardIcon1.svg";
import IconCopy from "../../../assets/copy.svg";
import Icon from "../../../assets/Netword/Icon.png";
import { useContext, useState } from "react";
import { ContextProviderWrapper } from "../../../components/Context";
import { formatNumber } from "../../../utils/formatNumber";
import Pagination from "../../../components/Pagination";

const LeftTeam = () => {
  const User = useSelector((state: RootState) => state.User.UserList);
  const limit = 10
  const NetwordInfo = useSelector(
    (state: RootState) => state.network.NetwordInfo
  );
  const { isMobile } = useContext(ContextProviderWrapper)!
  const [pageIndex , setPageIndex] = useState(0)
  const KeyTable = [
    {
      th: "Wallet Address",
      key: "",
      callback: (data: any) =>
        <>
          {!isMobile ? <>
            {`${data?.address?.slice(0, 3)}...${data?.address?.slice(-3)}`}
          </> : <>
            <div>
              <img width={34} height={34} src={"/assets/icon/avatar_network.png"} alt="avatar" />
              {`${data?.address?.slice(0, 3)}...${data?.address?.slice(-3)}`}
            </div>
          </>}
        </>
    },
    {
      th: "Sponser",
      key: "",
      callback: (data: any) => {
        return (
          <>
            {!isMobile ? <>
              {data.sponser.slice(0, 3)}...{data.sponser.slice(-3)}
            </> : <>
              <div>
                <p>Sponsor:</p> {data.sponser.slice(0, 3)}...{data.sponser.slice(-3)}
              </div>
            </>}
          </>
        )
      }
      ,
    },
    {
      th: "Total Farming",
      key: "",
      callback: (data: any) => {
        return (
          <>
            {!isMobile ? <>
              ~${formatNumber(data.farming_usd?.toFixed(2))}
            </> : <>
              <div>
                <p>Total Farming:</p> {formatNumber(data.farming_usd?.toFixed(2))}
              </div>
            </>}
          </>
        )
      }
      ,
    },
    {
      th: "Total Invest", key: "invest_token", callback: (data: any) =>
        <>
          {!isMobile ? <>
            {formatNumber(data.invest_token?.toFixed(2))} (~${formatNumber(data.invest_usd?.toFixed(2))})
          </> : <>
            <div>
              <p>Total Invest:</p> {formatNumber(data.invest_token?.toFixed(2))}
            </div>
          </>}
        </>
    },
    // {
    //   th: "Level", key: "level", callback: (data: any) =>
    //     <>
    //       {!isMobile ? <>
    //         {data.level}
    //       </> : <>
    //         <div>
    //           level: {data.level}
    //         </div>
    //       </>}
    //     </>
    // },
  ];
  
  return (
    <BoxElm>
      {!isMobile && <>
        <BoxTitleRadius>
          <h3>
            Left team <span>{NetwordInfo?.left_team_number || 0}</span>{" "}
          </h3>
          {User?.left_code && <CopyToClipboard
            text={`${window.location.origin}/${User?.left_code}`}
            onCopy={() => {
              toast.success("Copied");
            }}
          >
            <ButtonCopyLink>
              <p>Copy link</p>
              <img src={IconCopy} />
            </ButtonCopyLink>
          </CopyToClipboard>}
          
        </BoxTitleRadius>
      </>}
      <BoxElmTable>
        {isMobile && User?.left_code &&
          <CopyToClipboard
            text={`${window.location.origin}/${User?.left_code}`}
            onCopy={() => {
              toast.success("Copied");
            }}
          >
            <ButtonCopyLink>
              <p>Copy link</p>
              <img src={IconCopy} />
            </ButtonCopyLink>
          </CopyToClipboard>}
        <BoxValueGroup>
          <BoxValueElm>
            <span>
              <img src={CardIcon1} alt="" />
            </span>
            <div>
              <p>Total Revenue</p>
              <h6>
                {/* {formatNumber(NetwordInfo?.left_team_revenue?.token?.toFixed(2)) || 0}{" "}
                <img src="./assets/ton_symbol.svg" />{" "}
                <p>(~${formatNumber(NetwordInfo?.left_team_revenue?.usd?.toFixed(2)) || 0}{" "}) </p> */}
                ~${formatNumber(NetwordInfo?.left_team_revenue?.usd?.toFixed(2)) || 0}{" "}
              </h6>
            </div>
          </BoxValueElm>
          <BoxValueElm>
            <span>
              <img src={"https://exchange.iton.co/assets/images/liquiditi/icon-leaf.png"} alt="" style={{filter:"brightness(50)"}} />
            </span>
            <div>
              <p>Total Farm</p>
              <h6>
                {/* {formatNumber(NetwordInfo?.left_team_revenue?.token?.toFixed(2)) || 0}{" "}
                <img src="./assets/ton_symbol.svg" />{" "}
                <p>(~${formatNumber(NetwordInfo?.left_team_revenue?.usd?.toFixed(2)) || 0}{" "}) </p> */}
                ~${formatNumber(NetwordInfo?.left_team_farming_revenue?.usd?.toFixed(2)) || 0}{" "}
              </h6>
            </div>
          </BoxValueElm>
          <BoxValueElm>
            <span>
              <img src={Icon} alt="" />
            </span>
            <div>
              <p>Total branch commission</p>
              <h6>
                {/* {formatNumber(NetwordInfo?.left_team_commission?.token?.toFixed(2)) || 0}{" "}
                <img src="./assets/ton_symbol.svg" />{" "}
                <p>(~${formatNumber(NetwordInfo?.left_team_commission?.usd?.toFixed(2)) || 0}{" "}) </p> */}
                ~${formatNumber(NetwordInfo?.left_team_commission?.usd?.toFixed(2)) || 0}{" "}
              </h6>
            </div>
          </BoxValueElm>
        </BoxValueGroup>
        <BlueTable>
          <Table
            data={
              (NetwordInfo?.left && NetwordInfo?.left?.slice(pageIndex * limit , (pageIndex + 1) * limit)) || []
            }
            keyObj={KeyTable}
          />
        </BlueTable>
        {NetwordInfo?.left && (
            <Pagination
              items={NetwordInfo?.left_team_number}
              itemsPerPage={limit}
              fetchApi={(page: any) => {
                setPageIndex(page[`pagination[page]`] - 1
                )
              }}
            />
          )}
      </BoxElmTable>
    </BoxElm>
  )
}

export default LeftTeam